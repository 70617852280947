$(document).ready(function(){

    $(document).on('click','.btn-register-individual', function() {
        document.getElementById("type-register").value = "individual";
        document.getElementById("btn-next-register").click();
    });

    $(document).on('click','.btn-register-company', function() {
        document.getElementById("type-register").value = "professional";
        document.getElementById("btn-next-register").click();
    });

});