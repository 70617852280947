function Menu() {
}

Menu.fn = {
    init: function () {
        Menu.fn.handle.call();
    },

    handle: function() {
        $(document).on('click', '.cat-item', function (event) {
            event.stopPropagation();
            var _this = $(this);

            if(_this.find('.child_menu').length) {
                var child = _this.find('.child_menu').first();

                if(child.hasClass('collapse')) {
                    child.removeClass('collapse').addClass('expand');
                    _this.find('.fa-angle-down').first().hide();
                    _this.find('.fa-angle-up').first().show();
                } else {
                    child.removeClass('expand').addClass('collapse');
                    _this.find('.fa-angle-down').first().show();
                    _this.find('.fa-angle-up').first().hide();
                }
            }
        });
    },

    rule: function () {
        $(document).ready(function () {
            Menu.fn.init.call(this);
        });
    }
};

Menu.fn.rule();