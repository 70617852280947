function FormBuilderFrontend() {}

FormBuilderFrontend.fn = {
    init: function () {
        FormBuilderFrontend.fn.toggleSubfields.call(this)
        FormBuilderFrontend.fn.removeRequiredForHiddenRequiredFields.call(this)
    },
    toggleSubfields: function () {
        $('.double-radio').find('input[type="radio"]').on('change', function () {
            $('.double-radio').find('.subfields').addClass('hidden')

            let currentSubfields = $(this).closest('.double-radio').find('.subfields')
            if ($(this).is(':checked')) {
                currentSubfields.removeClass('hidden')
            }

            FormBuilderFrontend.fn.removeRequiredForHiddenRequiredFields.call(this)
        })
    },
    removeRequiredForHiddenRequiredFields: function () {
        let currentSubfields = $('.double-radio').find('.subfields')
        $.each(currentSubfields, function (index, currentSubfield) {
            let inputs = $(currentSubfield).find('input')
            $.each(inputs, function (indexInput, input) {
                let isRequired = $(input).data('isrequired')
                if (isRequired == 1) {
                    if ($(currentSubfield).hasClass('hidden')) {
                        $(input).prop('required', false)
                    } else {
                        $(input).prop('required', true)
                    }
                }
            })
        })
    },
    rules: function () {
        $(document).ready(function () {
            FormBuilderFrontend.fn.init.call(this)
        })
    }
}

FormBuilderFrontend.fn.rules();